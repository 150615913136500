<template>
    <main>
        <template v-if="objects.list.length">
            <h1 class="heading">Reviews</h1>
          <div class="event_header" style="margin-bottom:15px;width:100%;">
            <div style="display: flex;flex:1;">
              <div style="flex:1;width:auto;padding-right:15px;">
                <v-select
                    v-model="objectItem"
                    hide-details
                    :items="objects.list"
                    item-value="id"
                    item-text="name"
                    return-object
                    dense
                    style="max-width: 300px;margin-right: 15px;"
                    outlined
                    @change="changeObject"
                >
                  <template v-slot:selection="{ item }">
                    <div>{{ item.name }}</div>
                    <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
                    <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
                    <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div>{{ item.name }}</div>
                    <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
                    <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
                    <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
                  </template>
                </v-select>
              </div>
              <div style="flex:1;display: flex;justify-content: flex-end">
                <DatePicker
                    :shortcuts="shortcuts"
                    format="DD.MM.YYYY"
                    v-model="dates"
                    @change="changeDates"
                    :style="`width:${isMobile?'155px':'255px'}`"
                    type="date"
                    range
                    placeholder="Select datetime range"
                >
                  <template v-slot:input>
                    <v-text-field
                        solo
                        flat
                        outlined
                        dense
                        :placeholder="$t('statistics[\'Период\']')"
                        readonly
                        hide-details
                        v-model="date_model"
                    ></v-text-field>
                  </template>
                </DatePicker>
              </div>
            </div>
          </div>
            <template v-if="profi">
                <template v-if="answers.length">
                    <section class="polls__section" v-if="statistics.length">
                        <div class="polls__header">
                            <h2 class="polls__title">{{$t('polls.caption_1')}}</h2>
                            <json-csv :data="json" name="Reviews.csv">
                                <v-btn class="polls__btn btn_text"
                                    text
                                    small
                                    :disabled="!json.length"
                                >
                                    <v-icon color="primary">mdi-download</v-icon>
                                    {{$t('polls["Скачать в CSV"]')}}
                                </v-btn>
                            </json-csv>
                        </div>
                    </section>
                  <v-dialog max-width="1110px"
                            content-class="dialog-background"
                            style="background-color: #fff;"
                            v-model="dialog"
                            @click:outside="dialog=false" scrollable
                  >
                    <div class="popup__content popup__content_email" style="padding:15px 10px 10px;background-color: #fff;">
                      <v-btn class="popup__close" small icon @click="dialog=false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <h4 style="font-weight: normal;margin-bottom:30px;">Date: {{dialog_date}}</h4>

                      <div v-for="(u,uind) in dialog_data" :key="`${u.id}_${uind}`" style="border:1px solid #eee;padding:5px;border-radius: 5px;margin-bottom: 5px;position: relative;padding:10px;">
                          <v-btn icon @click="openDeletePoll(u,uind)" style="position: absolute;top:5px;right:5px;"><v-icon>mdi-delete</v-icon></v-btn>
                      <div v-for="(item,ind) in u" :key="`${item.id}_${ind}`" style="position: relative;">
                        <div v-if="ind === 0" style="display:flex;align-items:center;margin-bottom:15px;">
                          <div style="margin-right:15px;">
                            <h4 style="font-weight: normal;">Person: Anonym</h4>
                          </div>
                          <div style="margin-right:15px;">
                            Time: {{$moment(+item.date).format('DD.MM.YYYY HH:mm')}}
                          </div>
                          <div style="display:flex;align-items:center;">
                            Language: <img
                              :src="`https://guest.mobi/3x2/${changeFormatLangPic(
                          item.lang
                        )}.svg`"
                              style="width: 25px; height: 25px; margin-left: 10px"
                              :alt="item.lang"
                          />
                          </div>
                        </div>

                        <div v-if="ind === 0"></div>
                        <h4 >{{ item.header }}</h4>
                        <div style="margin-left:5px;" v-for="quest in item.questions" :key="quest.id">
                          <h5 style="font-weight: normal;" :style="`margin-right:5px;`" v-if="quest.type !== 'radio'">{{quest.name}}</h5>
                          <v-rating class="polls__stars"
                                    v-if="quest.type === 'star'"
                            color="#ffac07"
                            background-color="#e4e4e4"
                            readonly
                            length="5"
                            size="30"
                            :value="quest.value"
                        ></v-rating>
                          <div v-if="quest.type === 'text'">
                            {{ quest.value }}
                          </div>
                          <div style="font-weight: normal;" :style="`margin-right:5px;`" v-if="quest.type === 'radio' && quest.value">
                            {{ quest.name }} &#10003;
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </v-dialog>
                    <section class="polls__section" v-if="details.items.length">
                      <v-data-table class="table"
                                    :items-per-page="25"
                                    :hide-default-footer="reviews_table.length<25"
                                    :footer-props="{'items-per-page-options': [5,10, 25, 30, 40, 50]}"
                      :headers="[{ text: 'Date', value: 'date',align:'center' },{ text: 'Reviews count', value: 'count',align:'center' },{ text: 'Result', value: 'result',align:'center' },]" :items="reviews_table" >
                        <template v-slot:body="{ items }">
                          <tr v-for="item in items" :key="item.Date" style="text-align: center;">
                            <td class="d-block d-sm-table-cell">{{ item.date }}</td>
                            <td class="d-block d-sm-table-cell">{{item.count}}</td>
                            <td class="d-block d-sm-table-cell" style="color:dodgerblue;cursor: pointer;" @click="showReviews(item.data,item.date)">{{item.result}}</td>
                          </tr>
                        </template>
                      </v-data-table>
<!--                        <v-data-table class="table"-->
<!--                            :headers="details.headers"-->
<!--                            :items="details.items"-->
<!--                            hide-default-footer-->
<!--                        >-->

                            <!-- :page.sync="polls.page"
                            :items-per-page="polls.itemsPerPage"
                            @page-count="polls.pageCount = $event" -->
                            <!-- <template v-slot:item.question_1="{ item }">
                                <v-rating class="polls__stars"
                                    color="#ffac07"
                                    background-color="#e4e4e4"
                                    readonly
                                    length="5"
                                    size="30"
                                    :value="item.question_1"
                                ></v-rating>
                            </template> -->
<!--                            <template v-slot:body="{ items }">-->
<!--                                <tbody>-->
<!--                                    <tr v-for="item in items" :key="item.id">-->
<!--                                        <td class="d-block d-sm-table-cell">{{ item.date }}</td>-->
<!--                                        &lt;!&ndash; <td class="d-block d-sm-table-cell">{{ item.id }}</td> &ndash;&gt;-->
<!--                                        <td class="d-block d-sm-table-cell" v-if="item.question_1 !== undefined">-->
<!--                                            <v-rating class="polls__stars"-->
<!--                                                color="#ffac07"-->
<!--                                                background-color="#e4e4e4"-->
<!--                                                readonly-->
<!--                                                length="5"-->
<!--                                                size="30"-->
<!--                                                :value="item.question_1"-->
<!--                                            ></v-rating>-->
<!--                                        </td>-->
<!--                                        <td class="d-block d-sm-table-cell" v-if="item.question_2 !== undefined">-->
<!--                                            <v-rating class="polls__stars"-->
<!--                                                color="#ffac07"-->
<!--                                                background-color="#e4e4e4"-->
<!--                                                readonly-->
<!--                                                length="5"-->
<!--                                                size="30"-->
<!--                                                :value="item.question_2"-->
<!--                                            ></v-rating>-->
<!--                                        </td>-->
<!--                                        <td class="d-block d-sm-table-cell" v-if="item.question_3 !== undefined">-->
<!--                                            <v-rating class="polls__stars"-->
<!--                                                color="#ffac07"-->
<!--                                                background-color="#e4e4e4"-->
<!--                                                readonly-->
<!--                                                length="5"-->
<!--                                                size="30"-->
<!--                                                :value="item.question_3"-->
<!--                                            ></v-rating>-->
<!--                                        </td>-->
<!--                                        <td class="d-block d-sm-table-cell">{{item.question_4}}</td>-->
<!--                                    </tr>-->
<!--                                </tbody>-->
                                <!-- <tbody>
                                    <tr v-for="item in items" :key="item.id">
                                        <td class="d-block d-sm-table-cell">{{ item.date }}</td>
                                        <td class="d-block d-sm-table-cell">{{ item.id }}</td>
                                        <td class="d-block d-sm-table-cell">{{ items[header] }}</td>
                                        <td class="d-block d-sm-table-cell">
                                            <v-rating class="polls__stars"
                                                color="#ffac07"
                                                background-color="#e4e4e4"
                                                readonly
                                                length="5"
                                                size="30"
                                                :value="item.question_1"
                                            ></v-rating>
                                        </td>
                                        <td class="d-block d-sm-table-cell">
                                            <v-rating class="polls__stars"
                                                color="#ffac07"
                                                background-color="#e4e4e4"
                                                readonly
                                                length="5"
                                                size="30"
                                                :value="item.question_2"
                                            ></v-rating>
                                        </td>
                                        <td class="d-block d-sm-table-cell">
                                            <v-rating class="polls__stars"
                                                color="#ffac07"
                                                background-color="#e4e4e4"
                                                readonly
                                                length="5"
                                                size="30"
                                                :value="item.question_3"
                                            ></v-rating>
                                        </td>
                                        <td class="d-block d-sm-table-cell">{{item.question_4}}</td>
                                    </tr>
                                </tbody> -->
<!--                            </template>-->
<!--                        </v-data-table>-->
                        <!-- <div class="table__footer">
                            <v-pagination
                                v-model="polls.page"
                                :length="polls.pageCount"
                            ></v-pagination>
                            <v-text-field
                                :value="polls.itemsPerPage"
                                label="Items per page"
                                type="number"
                                min="-1"
                                max="15"
                                @input="polls.itemsPerPage = parseInt($event, 10)"
                            ></v-text-field>
                        </div> -->
                    </section>
                </template>
                <div v-else style="width: 100%;text-align: center;">
                    Your guests have not left any reviews yet.
                </div>
              <div class="polls__delete" v-if="answers.length">
                <v-btn class="btn_text"
                       text
                       small
                       color="error"
                       @click="deleteStatictic"
                >
                  <v-icon>
                    mdi-delete-outline
                  </v-icon>
                  {{$t('polls["Удалить статистику"]')}}
                </v-btn>
              </div>
              <PopupDelete ref="PopupDelete" @confirm="confirm" :title="'Are you sure about delete statistics?'"/>

                <!-- <h2 class="polls-empty" v-if="!statistics.length && !details.items.length">Здесь пока пусто, добавьте опросы для этого отеля.</h2> -->
<!--                <h2 class="polls-empty" v-else>{{$t('polls["Здесь будут отображаться отзывы ваших посетителей."]')}}</h2>-->
            </template>
            <template v-else>
                <h2 class="polls__profi">Reviews are available only for the Profi tariff</h2>
            </template>
        </template>
        <ObjectsEmpty :title="$t('polls.title')" v-else />
        <PopupDelete ref="PopupDeletePoll" @confirm="deletePool" :title="'Are you sure about delete poll?'"/>
    </main>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex';
import titleMixin from '@/mixins/titleMixin'
import moment from "moment";
import ObjectsEmpty from '@/components/ObjectsEmpty.vue'
import PopupDelete from '@/components/Popup/PopupDelete.vue'
import JsonCsv from 'vue-json-csv'
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    name: 'Polls',
    title() {
        return `${this.title}`
    },
    mixins: [titleMixin],
    components: {
        ObjectsEmpty,
        DatePicker,
        PopupDelete,
        JsonCsv
    },
    beforeRouteEnter (to, from, next) {
        if(!store.state.objects.list.length){
            store.dispatch('objects/getList')
                .then((data) => {
                    next();
                }).catch(() => {
                next();
            })
        }else{
            next();
        }
    },
    computed: {
        ...mapState(['objects']),
        ...mapState('polls', [
            'statistics',
            'text_answers',
            'text_question',
            'answers'
        ]),
      reviews_table(){
        let resultArr = [];
        for(let i = 0;i<this.data_review.length;i++){
          let obj = {
            date:moment(+this.data_review[i].created_at*1000).format('DD.MM.YYYY'),
            count:1,
            result:'Show daily result',
            data:[this.data_review[i].answer_4]
          };
          if(resultArr.length){
            for(let index = 0;index<resultArr.length;index++){
              if(resultArr[index].date === obj.date){
                resultArr[index].count+=1;
                resultArr[index].data.push(this.data_review[i].answer_4);
                break
              }else{
                resultArr.push(obj);
                break
              }
            }
          }
          else{
            resultArr.push(obj)
          }
        }
        return resultArr
      },
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    data() {
        return {
          isMobile:false,
          countries: [
            { value: "AF", label: "Afghanistan" },
            { value: "AX", label: "Åland Islands" },
            { value: "AL", label: "Albania" },
            { value: "DZ", label: "Algeria" },
            { value: "AS", label: "American Samoa" },
            { value: "AD", label: "Andorra" },
            { value: "AO", label: "Angola" },
            { value: "AI", label: "Anguilla" },
            { value: "AQ", label: "Antarctica" },
            { value: "AG", label: "Antigua and Barbuda" },
            { value: "AR", label: "Argentina" },
            { value: "AM", label: "Armenia" },
            { value: "AW", label: "Aruba" },
            { value: "AU", label: "Australia" },
            { value: "AT", label: "Austria" },
            { value: "AZ", label: "Azerbaijan" },
            { value: "BS", label: "Bahamas" },
            { value: "BH", label: "Bahrain" },
            { value: "BD", label: "Bangladesh" },
            { value: "BB", label: "Barbados" },
            { value: "BY", label: "Belarus" },
            { value: "BE", label: "Belgium" },
            { value: "BZ", label: "Belize" },
            { value: "BJ", label: "Benin" },
            { value: "BM", label: "Bermuda" },
            { value: "BT", label: "Bhutan" },
            { value: "BO", label: "Bolivia, Plurinational State of" },
            { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
            { value: "BA", label: "Bosnia and Herzegovina" },
            { value: "BW", label: "Botswana" },
            { value: "BV", label: "Bouvet Island" },
            { value: "BR", label: "Brazil" },
            { value: "IO", label: "British Indian Ocean Territory" },
            { value: "BN", label: "Brunei Darussalam" },
            { value: "BG", label: "Bulgaria" },
            { value: "BF", label: "Burkina Faso" },
            { value: "BI", label: "Burundi" },
            { value: "KH", label: "Cambodia" },
            { value: "CM", label: "Cameroon" },
            { value: "CA", label: "Canada" },
            { value: "CV", label: "Cape Verde" },
            { value: "KY", label: "Cayman Islands" },
            { value: "CF", label: "Central African Republic" },
            { value: "TD", label: "Chad" },
            { value: "CL", label: "Chile" },
            { value: "CN", label: "China" },
            { value: "CX", label: "Christmas Island" },
            { value: "CC", label: "Cocos (Keeling) Islands" },
            { value: "CO", label: "Colombia" },
            { value: "KM", label: "Comoros" },
            { value: "CG", label: "Congo" },
            { value: "CD", label: "Congo, the Democratic Republic of the" },
            { value: "CK", label: "Cook Islands" },
            { value: "CR", label: "Costa Rica" },
            { value: "CI", label: "Côte d'Ivoire" },
            { value: "HR", label: "Croatia" },
            { value: "CU", label: "Cuba" },
            { value: "CW", label: "Curaçao" },
            { value: "CY", label: "Cyprus" },
            { value: "CZ", label: "Czech Republic" },
            { value: "DK", label: "Denmark" },
            { value: "DJ", label: "Djibouti" },
            { value: "DM", label: "Dominica" },
            { value: "DO", label: "Dominican Republic" },
            { value: "EC", label: "Ecuador" },
            { value: "EG", label: "Egypt" },
            { value: "SV", label: "El Salvador" },
            { value: "GQ", label: "Equatorial Guinea" },
            { value: "ER", label: "Eritrea" },
            { value: "EE", label: "Estonia" },
            { value: "ET", label: "Ethiopia" },
            { value: "FK", label: "Falkland Islands (Malvinas)" },
            { value: "FO", label: "Faroe Islands" },
            { value: "FJ", label: "Fiji" },
            { value: "FI", label: "Finland" },
            { value: "FR", label: "France" },
            { value: "GF", label: "French Guiana" },
            { value: "PF", label: "French Polynesia" },
            { value: "TF", label: "French Southern Territories" },
            { value: "GA", label: "Gabon" },
            { value: "GM", label: "Gambia" },
            { value: "GE", label: "Georgia" },
            { value: "DE", label: "Germany" },
            { value: "GH", label: "Ghana" },
            { value: "GI", label: "Gibraltar" },
            { value: "GR", label: "Greece" },
            { value: "GL", label: "Greenland" },
            { value: "GD", label: "Grenada" },
            { value: "GP", label: "Guadeloupe" },
            { value: "GU", label: "Guam" },
            { value: "GT", label: "Guatemala" },
            { value: "GG", label: "Guernsey" },
            { value: "GN", label: "Guinea" },
            { value: "GW", label: "Guinea-Bissau" },
            { value: "GY", label: "Guyana" },
            { value: "HT", label: "Haiti" },
            { value: "HM", label: "Heard Island and McDonald Mcdonald Islands" },
            { value: "VA", label: "Holy See (Vatican City State)" },
            { value: "HN", label: "Honduras" },
            { value: "HK", label: "Hong Kong" },
            { value: "HU", label: "Hungary" },
            { value: "IS", label: "Iceland" },
            { value: "IN", label: "India" },
            { value: "ID", label: "Indonesia" },
            { value: "IR", label: "Iran, Islamic Republic of" },
            { value: "IQ", label: "Iraq" },
            { value: "IE", label: "Ireland" },
            { value: "IM", label: "Isle of Man" },
            { value: "IL", label: "Israel" },
            { value: "IT", label: "Italy" },
            { value: "JM", label: "Jamaica" },
            { value: "JP", label: "Japan" },
            { value: "JE", label: "Jersey" },
            { value: "JO", label: "Jordan" },
            { value: "KZ", label: "Kazakhstan" },
            { value: "KE", label: "Kenya" },
            { value: "KI", label: "Kiribati" },
            { value: "KP", label: "Korea, Democratic People's Republic of" },
            { value: "KR", label: "Korea, Republic of" },
            { value: "KW", label: "Kuwait" },
            { value: "KG", label: "Kyrgyzstan" },
            { value: "LA", label: "Lao People's Democratic Republic" },
            { value: "LV", label: "Latvia" },
            { value: "LB", label: "Lebanon" },
            { value: "LS", label: "Lesotho" },
            { value: "LR", label: "Liberia" },
            { value: "LY", label: "Libya" },
            { value: "LI", label: "Liechtenstein" },
            { value: "LT", label: "Lithuania" },
            { value: "LU", label: "Luxembourg" },
            { value: "MO", label: "Macao" },
            { value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
            { value: "MG", label: "Madagascar" },
            { value: "MW", label: "Malawi" },
            { value: "MY", label: "Malaysia" },
            { value: "MV", label: "Maldives" },
            { value: "ML", label: "Mali" },
            { value: "MT", label: "Malta" },
            { value: "MH", label: "Marshall Islands" },
            { value: "MQ", label: "Martinique" },
            { value: "MR", label: "Mauritania" },
            { value: "MU", label: "Mauritius" },
            { value: "YT", label: "Mayotte" },
            { value: "MX", label: "Mexico" },
            { value: "FM", label: "Micronesia, Federated States of" },
            { value: "MD", label: "Moldova, Republic of" },
            { value: "MC", label: "Monaco" },
            { value: "MN", label: "Mongolia" },
            { value: "ME", label: "Montenegro" },
            { value: "MS", label: "Montserrat" },
            { value: "MA", label: "Morocco" },
            { value: "MZ", label: "Mozambique" },
            { value: "MM", label: "Myanmar" },
            { value: "NA", label: "Namibia" },
            { value: "NR", label: "Nauru" },
            { value: "NP", label: "Nepal" },
            { value: "NL", label: "Netherlands" },
            { value: "NC", label: "New Caledonia" },
            { value: "NZ", label: "New Zealand" },
            { value: "NI", label: "Nicaragua" },
            { value: "NE", label: "Niger" },
            { value: "NG", label: "Nigeria" },
            { value: "NU", label: "Niue" },
            { value: "NF", label: "Norfolk Island" },
            { value: "MP", label: "Northern Mariana Islands" },
            { value: "NO", label: "Norway" },
            { value: "OM", label: "Oman" },
            { value: "PK", label: "Pakistan" },
            { value: "PW", label: "Palau" },
            { value: "PS", label: "Palestine, State of" },
            { value: "PA", label: "Panama" },
            { value: "PG", label: "Papua New Guinea" },
            { value: "PY", label: "Paraguay" },
            { value: "PE", label: "Peru" },
            { value: "PH", label: "Philippines" },
            { value: "PN", label: "Pitcairn" },
            { value: "PL", label: "Poland" },
            { value: "PT", label: "Portugal" },
            { value: "PR", label: "Puerto Rico" },
            { value: "QA", label: "Qatar" },
            { value: "RE", label: "Réunion" },
            { value: "RO", label: "Romania" },
            { value: "RU", label: "Russian Federation" },
            { value: "RW", label: "Rwanda" },
            { value: "BL", label: "Saint Barthélemy" },
            { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
            { value: "KN", label: "Saint Kitts and Nevis" },
            { value: "LC", label: "Saint Lucia" },
            { value: "MF", label: "Saint Martin (French part)" },
            { value: "PM", label: "Saint Pierre and Miquelon" },
            { value: "VC", label: "Saint Vincent and the Grenadines" },
            { value: "WS", label: "Samoa" },
            { value: "SM", label: "San Marino" },
            { value: "ST", label: "Sao Tome and Principe" },
            { value: "SA", label: "Saudi Arabia" },
            { value: "SN", label: "Senegal" },
            { value: "RS", label: "Serbia" },
            { value: "SC", label: "Seychelles" },
            { value: "SL", label: "Sierra Leone" },
            { value: "SG", label: "Singapore" },
            { value: "SX", label: "Sint Maarten (Dutch part)" },
            { value: "SK", label: "Slovakia" },
            { value: "SI", label: "Slovenia" },
            { value: "SB", label: "Solomon Islands" },
            { value: "SO", label: "Somalia" },
            { value: "ZA", label: "South Africa" },
            { value: "GS", label: "South Georgia and the South Sandwich Islands" },
            { value: "SS", label: "South Sudan" },
            { value: "ES", label: "Spain" },
            { value: "LK", label: "Sri Lanka" },
            { value: "SD", label: "Sudan" },
            { value: "SR", label: "Suriname" },
            { value: "SJ", label: "Svalbard and Jan Mayen" },
            { value: "SZ", label: "Swaziland" },
            { value: "SE", label: "Sweden" },
            { value: "CH", label: "Switzerland" },
            { value: "SY", label: "Syrian Arab Republic" },
            { value: "TW", label: "Taiwan, Province of China" },
            { value: "TJ", label: "Tajikistan" },
            { value: "TZ", label: "Tanzania, United Republic of" },
            { value: "TH", label: "Thailand" },
            { value: "TL", label: "Timor-Leste" },
            { value: "TG", label: "Togo" },
            { value: "TK", label: "Tokelau" },
            { value: "TO", label: "Tonga" },
            { value: "TT", label: "Trinidad and Tobago" },
            { value: "TN", label: "Tunisia" },
            { value: "TR", label: "Turkey" },
            { value: "TM", label: "Turkmenistan" },
            { value: "TC", label: "Turks and Caicos Islands" },
            { value: "TV", label: "Tuvalu" },
            { value: "UG", label: "Uganda" },
            { value: "UA", label: "Ukraine" },
            { value: "AE", label: "United Arab Emirates" },
            { value: "GB", label: "United Kingdom" },
            { value: "US", label: "United States" },
            { value: "UM", label: "United States Minor Outlying Islands" },
            { value: "UY", label: "Uruguay" },
            { value: "UZ", label: "Uzbekistan" },
            { value: "VU", label: "Vanuatu" },
            { value: "VE", label: "Venezuela, Bolivarian Republic of" },
            { value: "VN", label: "Viet Nam" },
            { value: "VG", label: "Virgin Islands, British" },
            { value: "VI", label: "Virgin Islands, U.S." },
            { value: "WF", label: "Wallis and Futuna" },
            { value: "EH", label: "Western Sahara" },
            { value: "YE", label: "Yemen" },
            { value: "ZM", label: "Zambia" },
            { value: "ZW", label: "Zimbabwe" },
          ],
          date_model: null,
          shortcuts: [
            {
              text: "Today",
              onClick() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
                const date = [start, end];
                return date;
              },
            },
            {
              text: "Yesterday",
              onClick() {
                const start = new Date();
                const end = new Date();
                end.setTime(end.getTime() + 1 * 24 * 3600 * 1000);
                const date = [start, end];
                return date;
              },
            },
            {
              text: "Last 7 day",
              onClick() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
                const date = [start, end];
                return date;
              },
            },
            {
              text: "Last 30 day",
              onClick() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
                const date = [start, end];
                return date;
              },
            },
            {
              text: "This month",
              onClick() {
                const start = new Date();
                const end = new Date();
                const firstDayCurrentMonth = new Date(
                    start.getFullYear(),
                    start.getMonth(),
                    1
                );
                const lastDayCurrefntMonth = new Date(
                    end.getFullYear(),
                    end.getMonth() + 1,
                    0
                );
                const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
                return date;
              },
            },
            {
              text: "Last month",
              onClick() {
                const dateLast = new Date();
                const firstDayCurrentMonth = new Date(
                    dateLast.getFullYear(),
                    dateLast.getMonth() - 1,
                    1
                );
                const lastDayCurrefntMonth = new Date(
                    dateLast.getFullYear(),
                    dateLast.getMonth(),
                    0
                );
                const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
                return date;
              },
            },
          ],
          objectItem:null,
            deleteItem:null,
            deleteIndex:null,
            dialog:false,
            title: "Reviews",
          dialog_date:null,
            menu_date_end: false,
            menu_date_start: false,
            filter: {
                date_end: null,
                date_start: null,
            },
            details: {
                headers: ['Date','Result'],
                items: [],
            },
            dates: {
                date_start: null,
                date_end: null,
            },
            object_id: +this.$route.query.object,
            json: [],
            profi: false,
          data_review:[],
          dialog_data:[]
        }
    },
    mounted(){
      this.dates[0] = this.$moment().format("YYYY-MM-DD");
      this.dates[1] = this.$moment().add(1, "months").format("YYYY-MM-DD");
      this.dateRangeText();
      if (this.objects.list.length) {
        if (this.$route.query.object) {
          const candidate = this.objects.list.find(
              (x) => x.id === +this.$route.query.object
          );
          if (candidate) {
            this.objectItem = candidate;
          } else {
            this.objectItem = this.objects.list[0];
          }
        } else {
          this.objectItem = this.objects.list[0];
        }
        this.changeObject(this.objectItem)
      }
    },
    methods: {
      changeFormatLangPic(lang) {
        const langArr = lang.split("-");
        if (langArr[1]) {
          const candidate = this.countries.find(
              (x) => x.value === langArr[1].toUpperCase()
          );
          if (candidate) {
            return candidate.value;
          }
        } else {
          return lang.toUpperCase();
        }
      },
      changeDates() {
        this.menu_dates = false;
        if (this.dates[0] && this.dates[1]) {
          if (this.dates[0] > this.dates[1]) {
            let start = this.dates[0];
            let end = this.dates[1];
            this.dates[0] = end;
            this.dates[1] = start;
          }
        }
        this.dateRangeText();
      },
      dateRangeText() {
        let date_start = null;
        let date_end = null;
        if (this.dates[0] && this.dates[1]) {
          date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
          date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
        } else if (this.dates[0]) {
          date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
        } else if (this.dates[1]) {
          date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
        } else {
          this.date_model = "";
        }

        this.date_model = [date_start, date_end].join(" - ");
      },
        openDeletePoll(item,index){
            this.deleteItem = item;
            this.deleteIndex = index;
            this.$refs.PopupDeletePoll.open();
        },
        async deletePool(){
            await this.$axios.delete(`delete-one-poll/${this.deleteItem[0].id}`);
            const candidateRow = this.reviews_table.find(row => row.date === this.$moment(this.deleteItem[0].date).format('DD.MM.YYYY'));
            if(candidateRow){
                candidateRow.data.splice(this.deleteIndex,1);
                candidateRow.count = +candidateRow.count-1
            }
            // this.dialog_data.splice(this.deleteIndex,1);
        },
        showReviews(arr,date){
        this.dialog_data = arr;
        this.dialog_date = date;
        this.dialog = true;
      },
        async changeObject(hotel) {
            let current_object = this.objects.list.find(object => +object.id === +hotel.id);
            if (current_object && current_object.tariff_id === 3) {
                this.profi = true;
            } else {
                this.profi = false;
            }
            this.$store.dispatch('polls/getList', {
                hotel_id: hotel.id,
                date_start: this.dates.date_start,
                date_end: this.dates.date_end,
            })
                .then((data) => {
                    this.data_review = data.data.polls_answers;
                    this.details.headers = [];
                    this.statistics.forEach((item, index) => {
                        this.details.headers.push({ text: item.name, value: `question_${index + 1}` })
                    });
                    this.details.items = [];
                    this.answers.forEach(answer => {
                        let item = {
                            date: this.$moment.unix(answer.created_at).format('DD.MM.YYYY'),
                        };
                        if (answer.rating_1 !== null) {
                            item.question_1 = +answer.rating_1;
                        }
                        if (answer.rating_2 !== null) {
                            item.question_2 = +answer.rating_2;
                        }
                        if (answer.rating_3 !== null) {
                            item.question_3 = +answer.rating_3;
                        }
                        if (answer.answer_4 !== '') {
                            item.question_4 = answer.answer_4;
                        }
                        this.details.items.push(item);
                    });

                    this.setJson();

                }).catch(error => {
                    this.details.items = [];
                })
        },
        deleteStatictic() {
            this.$refs.PopupDelete.open();
        },
        setJson() {
            let json = JSON.parse(JSON.stringify(this.details.items.slice(0)));
            this.json = [];
            json.forEach(item => {
                this.details.headers.forEach(header => {
                    if (item[header.value]) {
                        item[header.text] = item[header.value];
                        delete item[header.value];
                        delete item.id;
                    }
                });
                this.json.push(item)
            });
        },
        confirm(value) {
            if (value) {
                this.$axios
                    .delete('delete-hotel-poll', {
                        params: {
                            hotel_id: this.object_id,
                        }
                    })
                    .then(response => {
                      if(this.object_id){
                        this.changeObject(this.object_id);
                      }
                    })
                    .catch(error => {
                        console.log(error.response);
                    });
            }
        },
        declOfNum(n, text_forms) {
            n = Math.abs(n) % 100;
            let n1 = n % 10;
            if (n > 10 && n < 20) { return text_forms[2]; }
            if (n1 > 1 && n1 < 5) { return text_forms[1]; }
            if (n1 == 1) { return text_forms[0]; }
            return text_forms[2];
        },
        declPeople(num) {
            if (this.$i18n.locale == 'ru') {
                return this.declOfNum(num,
                    [
                        this.$t('polls["человек"]'),
                        this.$t('polls["человека"]'),
                        this.$t('polls["человек"]')
                    ]
                );
            } else {
                return this.declOfNum(num,
                    [
                        this.$t('polls["человек"]'),
                        this.$t('polls["человек"]'),
                        this.$t('polls["человек"]')
                    ]
                );
            }
        },
        declAnswers(num) {
            if (this.$i18n.locale == 'ru') {
                return this.declOfNum(num,
                    [
                        this.$t('polls["ответ"]'),
                        this.$t('polls["ответа"]'),
                        this.$t('polls["ответов"]')
                    ]
                );
            } else {
                return this.declOfNum(num,
                    [
                        this.$t('polls["ответ"]'),
                        this.$t('polls["ответов"]'),
                        this.$t('polls["ответов"]')
                    ]
                );
            }
        }
    }
};
</script>

<style lang="scss">
.polls__section {
    margin-bottom: 30px;
}

.polls__header {
    display: flex;
    align-items: center;
    // margin-bottom: 30px;
}

.polls__title {
    @include title;
    margin-right: auto;
    margin-bottom: 20px;
}

.polls__list {
    @include box;
    border-radius: 5px 5px 0 0;
}

.polls__item {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    &:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;
    }
}

.polls__question {
    @include title;
    width: 50%;
    padding-right: 15px;
}

.polls__statistics {
    width: 50%;
    padding-left: 15px;
}

.polls__statistic {
    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

.polls__progress {
    margin-bottom: 10px;
}

.polls__rating {
    display: flex;
    align-items: center;
}

.polls__stars {

}

.polls__percent {
    margin-right: 20px;
    font-weight: 700;
    font-size: 16px;
}

.polls__people {
    font-weight: 500;
    font-size: 16px;
}

.polls__footer {
    display: flex;
    align-items: center;
    border: solid 1px #e4e4e4;
    border-top: none;
    padding: 20px 30px;
    border-radius: 0 0 5px 5px;
}

.polls__total {
    width: 50%;
    padding-right: 15px;
    font-size: 24px;
    font-weight: 600;
    span {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #7f8385;
    }
}

.polls__delete {
    width: 50%;
    padding-left: 15px;
}

.polls-empty {
    position: relative;
    display: flex;
    max-width: 350px;
    margin: 280px auto;
    padding-top: 150px;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.33;
    color: #a3a4a5;
    background: url(~@/assets/img/hotel.svg) center top/120px no-repeat;
}
.dialog-background{
  background-color: #FFFFFF;
}
.polls__btn {
    margin-bottom: 20px;
}

.polls__profi {
    text-align: center;
  font-weight: normal;
  font-size: 16px;
}
</style>
